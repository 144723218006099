import { flow, Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IBlogPostModel } from "../types/types"

export const BlogStoreModel = types
  .model("BlogStoreModel")
  .props({
    isLoading: types.maybeNull(types.boolean),
    blogPosts: types.array(types.frozen<IBlogPostModel>()),
    // New property to store featured posts
    featuredPosts: types.optional(types.array(types.frozen<IBlogPostModel>()), []),
    page: types.optional(types.number, 1),
    totalPages: types.maybeNull(types.number),
    currentQueryParams: types.frozen(),
    order: types.maybeNull(types.string),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .actions((self) => ({
    getInitialResults: flow(function* (queryParams: any, order: string) {
      self.isLoading = true
      self.currentQueryParams = queryParams
      self.page = 1
      self.order = order
      const params = { ...self.currentQueryParams }
      const response = yield self.environment.api.fetchBlogPosts(params, self.order, self.page)
      if (response.ok) {
        // Update regular blog posts
        self.blogPosts = response.data?.blogPosts || []
        // Update featured posts from the endpoint's "featured" key
        self.featuredPosts = response.data?.featured || []
        self.totalPages = response.data.totalPages
      }
      self.isLoading = false
    }),
    getTargetPage: flow(function* (page: number) {
      self.isLoading = true
      self.page = page
      const params = { ...self.currentQueryParams }
      const response = yield self.environment.api.fetchBlogPosts(params, self.order, self.page)
      if (response.ok) {
        self.blogPosts = response.data?.blogPosts || []
        // Update featured posts here as well in case they change across pages
        self.featuredPosts = response.data?.featured || []
      }
      self.isLoading = false
      return response
    }),
  }))

export interface IBlogStore extends Instance<typeof BlogStoreModel> {}
