import { ApisauceInstance, create, Monitor } from "apisauce"
import { cartItemsParams, creditParams } from "."
import { ICartInProgressItem, ICartItem, ICredit } from "../../../models"
import { camelizeResponse, decamelizeRequest } from "../../../utils"

interface IApiConfig {
  baseURL: string
  logging: boolean
}

export class Api {
  apiConfig: IApiConfig
  client: ApisauceInstance

  constructor() {
    this.apiConfig = {
      baseURL: process.env.NEXT_PUBLIC_API_SERVER_URL,
      logging: true,
    }

    this.client = create({
      baseURL: this.apiConfig.baseURL,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Device-Resolution": "@3x",
        "Order-Source": "web",
        //TODO: SWAP OUT DEVICE-RESOLUTION FOR PROPER WEB SIZED IMAGES
        // "Device-Resolution": `${PixelRatio.get() === 3 ? "@3x" : "@2x"}`,
      },
      timeout: 30000,
      withCredentials: true,
    })

    this.client.addResponseTransform((response) => {
      response.data = camelizeResponse(response.data)
    })

    this.client.addRequestTransform((request) => {
      request.params = decamelizeRequest(request.params)
      request.data = decamelizeRequest(request.data)
    })
  }

  addMonitor(monitor: Monitor) {
    this.client.addMonitor(monitor)
  }

  setAuthHeaders(headers: { [key: string]: string }) {
    this.client.setHeader("access-token", headers["access-token"])
    this.client.setHeader("client", headers["client"])
    this.client.setHeader("uid", headers["uid"])
  }

  clearAuthHeaders() {
    this.client.deleteHeader("access-token")
    this.client.deleteHeader("client")
    this.client.deleteHeader("uid")
  }

  setClientHeader(selectedClient?: number) {
    selectedClient && this.client.setHeader("selected-erp-customer", selectedClient.toString())
  }

  clearClientHeader() {
    this.client.deleteHeader("selected-erp-customer")
  }

  async fetchCatalogue() {
    return this.client.get("/catalogue")
  }

  async fetchPromotions() {
    return this.client.get("/promotions")
  }

  async fetchPromotion(id) {
    return this.client.get(`/promotions/${id}`)
  }

  async applyPromotionV2(promotionId, values) {
    return this.client.post(`/v2/promotions/${promotionId}/apply`, values)
  }

  async promotionSearch(params) {
    return this.client.post("/promotion_search", params)
  }

  async getAvailablePromotionFilters(params) {
    return this.client.post("/v2/promotions/available_filters", params)
  }

  async getBecomeAPartnerContent() {
    return this.client.get("/become_a_partner_contents")
  }

  async login(email: string, password: string) {
    return this.client.post("auth/sign_in", { email, password })
  }

  async logout() {
    return this.client.delete("auth/sign_out")
  }

  async validateToken() {
    return this.client.get("/validate_token")
  }

  async getProducts(
    categoryId?: number,
    serviceItemGroupSlug?: string,
    specialEquipmentCodeSlug?: string,
    brandSlug?: string,
    vendorSlug?: string,
    page?: number,
    q?: string,
    sort?: string,
    brand?: string[],
    itemClass?: string[],
    productGroup?: string[],
    samplesOnly?: boolean,
  ) {
    return this.client.post("/search", {
      categoryId,
      serviceItemGroupSlug,
      specialEquipmentCodeSlug,
      brandSlug,
      vendorSlug,
      page,
      q,
      sort,
      brand,
      itemClass,
      productGroup,
      samplesOnly,
    })
  }

  async getProductsV2(
    categoryId?: number,
    serviceItemGroupSlug?: string,
    specialEquipmentCodeSlug?: string,
    brandSlug?: string,
    vendorSlug?: string,
    page?: number,
    q?: string,
    sort?: string,
    brand?: string[],
    itemClass?: string[],
    productGroup?: string[],
    samplesOnly?: boolean,
  ) {
    return this.client.post("/v2/products/search", {
      categoryId,
      serviceItemGroupSlug,
      specialEquipmentCodeSlug,
      brandSlug,
      vendorSlug,
      page,
      q,
      sort,
      brand,
      itemClass,
      productGroup,
      samplesOnly,
    })
  }

  async quickSearchV2(q: string) {
    return this.client.post("/v2/products/quick_search", { q })
  }

  async getAvailableFilters(
    categoryId?: number,
    serviceItemGroupSlug?: string,
    specialEquipmentCodeSlug?: string,
    brandSlug?: string,
    vendorSlug?: string,
    q?: string,
    brand?: string[],
    itemClass?: string[],
    productGroup?: string[],
    samplesOnly?: boolean,
  ) {
    return this.client.post("/available_filters", {
      categoryId,
      serviceItemGroupSlug,
      specialEquipmentCodeSlug,
      brandSlug,
      vendorSlug,
      q,
      brand,
      itemClass,
      productGroup,
      samplesOnly,
    })
  }

  async getProductById(id: number | string) {
    return this.client.get(`/products/${id}`)
  }

  async fetchCartV2() {
    return this.client.get("/v2/cart")
  }

  async priceCartV2() {
    const r = this.client.post("/v2/cart/price")
    return r
  }

  async fetchCart() {
    return this.client.get("/cart")
  }

  async addCartItemsV2(cartItems: ICartInProgressItem[]) {
    return this.client.post("/v2/carts/add_cart_items", { cartItems })
  }

  async addMultipleToCart(cartItems: ICartInProgressItem[]) {
    return this.client.post("/carts/multiple", { cartItems })
  }

  async addToCart(anipetItemNumber: string, quantity: number) {
    return this.client.post("/cart", {
      cart_item: {
        product_number: anipetItemNumber,
        quantity: quantity,
      },
    })
  }

  async addToCartFromOrderTemplateV2(orderTemplateId: number, replaceCart?: boolean) {
    return this.client.post("/v2/cart/add_to_cart_from_order_template/", {
      order_template_id: orderTemplateId,
      replaceCart,
    })
  }

  async updateCartItem(anipetItemNumber: string, quantity: number) {
    return this.client.put("/cart", {
      cart_item: {
        product_number: anipetItemNumber,
        quantity: quantity,
      },
    })
  }

  async updateCartItemV2(anipetItemNumber: string, quantity: number) {
    return this.client.put("/v2/cart", {
      cart_item: {
        product_number: anipetItemNumber,
        quantity: quantity,
      },
    })
  }

  async deleteCartItem(id: number) {
    return this.client.delete("/cart_items/" + id)
  }

  async getProductByBarcode(barcode: string) {
    return this.client.post("/barcode", { barcode: barcode })
  }

  async checkout(orderNotes: string | null, externalDocumentNumber: string | null, usePromotionalCart = false) {
    return this.client.post("/cart/checkout", {
      orderNotes,
      externalDocumentNumber,
      usePromotionalCart,
    })
  }

  async checkoutV2(orderNotes: string | null, externalDocumentNumber: string | null, usePromotionalCart = false) {
    return this.client.post("/v2/cart/checkout", {
      orderNotes,
      externalDocumentNumber,
      usePromotionalCart,
    })
  }

  async sendForgotPasswordEmail(email: string) {
    return this.client.post("auth/password", { email })
  }

  async changePassword(newPassword: string, newPasswordConfirmation: string) {
    return this.client.put(
      "/auth/password",
      {
        new_password: newPassword,
        password_confirmation: newPasswordConfirmation,
      },
      { headers: { "Allow-Change": "true" } },
    )
  }

  async getAppUsers(type: string) {
    return this.client.get("/app_users", { type })
  }

  async createAppUser(email: string, firstName: string, lastName: string, role: number) {
    return this.client.post("/app_users", { email, firstName, lastName, role })
  }

  async deleteAppUser(id: string) {
    return this.client.delete(`/app_users/${id}`)
  }

  async getSalesOrders() {
    return this.client.get("/sales_orders")
  }

  async searchSalesOrders(params) {
    return this.client.get("/sales_orders/search", { q: params })
  }

  async getSalesOrderNotes(number: string) {
    return this.client.get(`/sales_orders/fetch_sales_order_notes/${number}`)
  }

  async searchSalesInvoices(params) {
    return this.client.get("/sales_invoices/search", { q: params })
  }

  async downloadInvoicePdf(systemId) {
    return this.client.post("/sales_invoices/download_invoice_pdf", { systemId })
  }

  async downloadCreditMemoPdf(systemId) {
    return this.client.post("/credits/download_credit_memo_pdf", { systemId })
  }

  async getAllCustomerPostedSalesCreditMemos(erpCustomerNumber) {
    return this.client.get("/credits/credit_memos_for_web", { erpCustomerNumber })
  }

  async fetchClients(query: string) {
    return this.client.post("/erp_customers", { query })
  }

  async setCurrentClient(erpCustomerId?: number | string | string[]) {
    return this.client.post("/set_erp_customer", { erpCustomerId })
  }

  async setCurrentClientV2(erpCustomerId?: number | string | string[]) {
    return this.client.post("/v2/set_erp_customer", { erpCustomerId })
  }

  async clearCurrentClient() {
    return this.client.post("/clear_erp_customer")
  }

  async getBrands(page?: number) {
    return this.client.get("/brands", {
      page,
    })
  }

  async getBrand(id: string) {
    return this.client.get(`/brands/${id}`)
  }

  async getBrandById(id: number | string) {
    return this.client.get(`/brands/${id}`)
  }

  async getValidPromotionById(id: number | string) {
    return this.client.get(`/sell_sheets/${id}`)
  }

  async quickSearch(q: string) {
    return this.client.post("/quick_search", { q })
  }

  async quickAddSearch(q: string) {
    return this.client.post("/quick_add_search", { q })
  }

  async fetchCredits(page?: number, status?: string) {
    return this.client.get("/credits", {
      page,
      status,
    })
  }

  async createCreditRequest(credit: ICredit) {
    return this.client.post("/credits", creditParams(credit))
  }

  async deleteCreditRequest(creditId: number) {
    return this.client.delete(`/credits/${creditId}`)
  }

  async updateCreditRequest(credit: ICredit, ogCredit: ICredit) {
    return this.client.patch(`/credits/${credit.id}`, creditParams(credit, ogCredit))
  }

  async fetchOrderTemplatesV2(page?: number, orderTemplateType?: string) {
    return this.client.get("/v2/order_templates", {
      page,
      order_template_type: orderTemplateType,
    })
  }

  async createOrderTemplate(params: { name; productNumber; quantity; orderTemplateType }) {
    return this.client.post("/order_templates", params)
  }

  async createOrderTemplateV2(params: { name; productNumber; quantity; orderTemplateType }) {
    return this.client.post("/v2/order_templates", params)
  }

  async createOrderTemplateItem(orderTemplateId: number, productNumber: string, quantity: number) {
    return this.client.post(`/order_template_items`, {
      orderTemplateId,
      productNumber,
      quantity,
    })
  }

  async createOrderTemplateItemV2(orderTemplateId: number, productNumber: string, quantity: number) {
    return this.client.post(`/v2/order_template_items`, {
      orderTemplateId,
      productNumber,
      quantity,
    })
  }

  async updateOrderTemplateV2(id: number, params: { name: string }) {
    return this.client.put(`/v2/order_templates/${id}`, params)
  }

  async deleteOrderTemplateV2(id: number) {
    return this.client.delete(`/v2/order_templates/${id}`)
  }

  async updateOrderTemplateItemV2(id: number, quantity: number) {
    return this.client.put(`/v2/order_template_items/${id}`, { quantity })
  }

  async deleteOrderTemplateItemV2(id: number) {
    return this.client.delete(`/v2/order_template_items/${id}`)
  }

  async removeCartItemsV2(cartItems: ICartItem[]) {
    return this.client.post("/v2/cart/remove_cart_items", cartItemsParams(cartItems))
  }

  async removeAllCartItemsV2() {
    return this.client.delete("/v2/cart")
  }

  async getPresignedUrl(type: string | undefined) {
    return this.client.get("/s3/presigned_url", { type })
  }

  async getTeams() {
    return this.client.get("/teams")
  }

  async shareWithRep(erpCustomerId: string | number, product: string | number) {
    return this.client.post(`/share_with_rep?erp_customer_id=${erpCustomerId}&product=${product}`)
  }

  async fetchBlogPosts(params, order = "", page = 1) {
    return this.client.get("/blog_posts", { q: params, order: order, page: page })
  }

  async fetchProductNotifications(page: number) {
    return this.client.get(`/products/notifications`, { page })
  }

  async fetchProductNotificationsV2(page: number) {
    return this.client.get(`/v2/products/notifications`, { page })
  }

  async deleteNotification(id) {
    return this.client.delete(`/products/notifications/${id}`)
  }

  // async fetchPromotionNotifications(page: number) {
  //   return this.client.get(`/promotions/notifications`, { page })
  // }

  async resetLastReadNotifications() {
    return this.client.put(`/notifications/reset_last_read`)
  }

  async createProductFollow(productId) {
    return this.client.post(`/products/${productId}/follows`)
  }

  async getCustomerBestPrices(erpCustomerNumber, productNumbers) {
    return this.client.post(`/customer_best_prices`, { erpCustomerNumber, productNumbers })
  }

  async getCustomerBestPricesV2(erpCustomerNumber, productNumbers) {
    return this.client.post(`/v2/products/customer_best_prices`, { erpCustomerNumber, productNumbers })
  }

  async fetchOtherPage(id) {
    return this.client.get(`/other_pages/${id}`)
  }

  async fetchSpecialEquipmentCode(id) {
    return this.client.get(`/special_equipment_codes/${id}`)
  }

  async fetchServiceItemGroup(id) {
    return this.client.get(`/service_item_groups/${id}`)
  }
}
