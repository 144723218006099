import { flow, IMSTArray, Instance, types } from "mobx-state-tree"
import { translate } from "../app/i18n"
import { values, toJS } from "mobx"
import * as R from "ramda"
import { withEnvironment, withRootStore } from "../lib"
import { CartModel, IPromotion, PromotionModel } from "../models"
import { EPromotionFilterKeys, EPromotionStatus, IFilterOption, IFilterType } from "../types"

export const PromotionStoreModel = types
  .model("PromotionStoreModel")
  .props({
    isLoading: types.maybeNull(types.boolean),
    promotionMap: types.map(PromotionModel),
    brand: types.optional(types.array(types.string), []),
    tag: types.optional(types.array(types.string), []),
    isSearching: types.maybeNull(types.boolean),
    showExpired: types.optional(types.boolean, false),
    page: types.optional(types.number, 1),
    totalPages: types.maybeNull(types.number),
    resultsCount: types.maybeNull(types.number),
    cart: types.maybeNull(CartModel),
    updateInProgress: types.maybeNull(types.boolean),
    sortBy: types.optional(types.string, ""),
    query: types.optional(types.string, ""),
    filters: types.array(types.frozen<IFilterType>()),
    isLoadingFilters: types.optional(types.boolean, false),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({
    get promotions(): IPromotion[] {
      //@ts-ignore
      return values(self.promotionMap)
    },
  }))
  .views((self) => ({
    get publishedPromotions(): IPromotion[] {
      return self.promotions.filter((p) => p.status === EPromotionStatus.published)
    },
  }))
  .views((self) => ({
    get sortOptions() {
      return [
        { label: "Expiry Date Ascending", value: "end_date asc" },
        { label: "Expiry Date Descending", value: "end_date desc" },
        { label: "Starting Date Ascending", value: "start_date asc" },
        { label: "Starting Date Descending", value: "start_date desc" },
        { label: "Name Ascending", value: "name asc" },
        { label: "Name Descending", value: "name desc" },
        { label: "Default", value: "position desc" },
      ]
    },
    get featuredPromotions(): IPromotion[] {
      //@ts-ignore
      return self.publishedPromotions.filter((p) => p.featured && !p.expired)
    },
    getPromotionBySlug(slug): IPromotion {
      //@ts-ignore
      return self.promotions.find((promo) => promo.slug === slug || promo.id == slug)
    },
    get promotionBrandOptions(): IFilterOption[] {
      const currentDate = new Date()
      return R.pipe(
        R.filter((promotion) =>
          self.showExpired ? new Date(promotion.endDate) < currentDate : new Date(promotion.endDate) >= currentDate,
        ),
        R.map(R.prop("brand")),
        R.filter(Boolean),
        R.uniq,
        R.sortBy(R.toLower),
        R.map((brand) => ({ displayName: brand, key: brand, applied: true })),
      )(self.promotions)
    },

    get groupedPromotions(): IPromotion[][] {
      if (self.promotions.length === 0) return null

      const byGroup = R.pipe(
        R.groupBy((promo) => promo.promotionGroup?.position),
        R.toPairs,
        R.sortBy(R.prop(0)),
        R.map(R.prop(1)),
      )
      return byGroup(self.promotions)
    },
    promotionsByGroupId(groupId): IPromotion[] {
      return self.promotions.filter((promo) => promo.promotionGroup.id === groupId)
    },
  }))
  .actions((self) => ({
    getAvailableFilters: flow(function* () {
      self.isLoadingFilters = true
      const response = yield self.environment.api.getAvailablePromotionFilters({
        q: self.query,
        brand: self.brand,
        tag: self.tag,
        expired: self.showExpired,
      })
      self.isLoadingFilters = false
      return response
    }),
    clearPromotionCart: () => (self.cart = null),
    toggleFilterOption(option: { [key: string]: any }, filterKey: EPromotionFilterKeys) {
      const isChecked = self[filterKey] && self[filterKey].length > 0 ? R.includes(option.key, self[filterKey]) : false

      if (isChecked) {
        self[filterKey].remove(option.key)
      } else {
        self[filterKey].push(option.key)
      }
    },
    setShowExpired(showExpired) {
      self.showExpired = showExpired
    },
    setSortBy(term) {
      self.sortBy = term
    },
    setQuery(query: string) {
      self.query = query
    },
    setBrand(brand: string[]) {
      self.brand = brand || ([] as any)
    },
    setTag(tag: string[]) {
      self.tag = tag || ([] as any)
    },
    setPage(page: number) {
      self.page = page || (1 as any)
    },
    clearFilterKey(filterKey) {
      self[filterKey] = []
    },
    setPromotions(promotions) {
      if (promotions) {
        self.promotionMap.clear()
        promotions.promotions.forEach((promo) => self.promotionMap.put(promo))
      }
    },
  }))
  .actions((self) => ({
    fetchPromotion: flow(function* (id) {
      const response = yield self.environment.api.fetchPromotion(id)
      if (response?.status > 300) return
      if (response.ok && response.data) {
        self.promotionMap.put(response.data)
      }
    }),
    applyPromotion: flow(function* (promotionId, values) {
      self.cart = null
      self.updateInProgress = true
      const response = yield self.environment.api.applyPromotionV2(promotionId, values)
      self.updateInProgress = false
      if (response.ok) {
        self.cart = response.data
        self.rootStore.uiStore.flashMessage.showAddToCart(
          translate("addToCartButton.title"),
          response.data?.meta?.message || "Promotion applied!",
          response.data?.meta?.message ? "warning" : "success",
          {},
        )
        return true
      } else {
        return false
      }
    }),
    promotionCheckout: flow(function* (orderNotes) {
      try {
        return yield self.environment.api.checkoutV2(orderNotes, null, true)
      } catch {
        // error messaging handled by API monitor
      }
    }),
  }))
  .actions((self) => ({
    fetchPromotions: flow(function* () {
      self.isLoading = true
      try {
        const response = yield self.environment.api.promotionSearch({
          q: self.query, // the keywords
          brand: self.brand,
          tag: self.tag,
          sort: self.sortBy,
          expired: self.showExpired,
          page: self.page,
        })
        if (response.ok) {
          self.setPromotions(response.data)
          self.resultsCount = response.data.total
          self.totalPages = response.data.pages
          const filtersResponse = yield self.getAvailableFilters()
          if (filtersResponse.ok) {
            self.filters = filtersResponse.data.availableFilters
          }
        }
      } catch (error) {
        // Optionally, handle errors here (e.g., log error)
      } finally {
        self.isLoading = false
      }
    }),
    resetPage(router) {
      router.query.page = "1"
      router.push(router)
      self.setPage(1)
    },
  }))
  .views((self) => ({
    get selectedSortLabel() {
      const option = self.sortOptions.find((opt) => opt.value === self.sortBy)
      return option ? option.label : "Select" // Fallback if not found
    },
  }))

export interface IPromotionStore extends Instance<typeof PromotionStoreModel> {}
