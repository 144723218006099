import { utcToZonedTime, format } from "date-fns-tz"
import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { EPromotionStatus, IProduct, IPromotionGroup, IPromotionLineItem, IPromotionSection } from "../types"
import { ICart } from "./cart"

export const PromotionModel = types.snapshotProcessor(
  types
    .model("PromotionModel")
    .props({
      id: types.identifierNumber,
      name: types.string,
      number: types.maybeNull(types.string),
      dimensionCode: types.optional(types.string, "Uncoded"),
      details: types.optional(types.string, "Select from products below"),
      brand: types.maybeNull(types.string),
      slug: types.maybeNull(types.string),
      pdfUrl: types.maybeNull(types.string),
      termsPrompt: types.maybeNull(types.string),
      termsExplanation: types.maybeNull(types.string),
      termsOptions: types.optional(types.array(types.string), []),
      startDate: types.Date,
      endDate: types.Date,
      status: types.enumeration(Object.values(EPromotionStatus)),
      coverImageUrl: types.maybeNull(types.string),
      desktopHeroUrl: types.maybeNull(types.string),
      mobileHeroUrl: types.maybeNull(types.string),
      tags: types.optional(types.array(types.string), []),
      promotionGroup: types.frozen<IPromotionGroup>(),
      featured: types.maybeNull(types.boolean),
      sections: types.maybeNull(types.array(types.frozen<IPromotionSection>())),
      redemptions: types.optional(types.number, 0),
      maxRedemptions: types.maybeNull(types.number),
      cartWarningsApplied: types.optional(types.boolean, false),
      comingSoon: types.maybeNull(types.boolean),
      presale: types.maybeNull(types.boolean),
      holdForStock: types.maybeNull(types.boolean),
      prebook: types.maybeNull(types.boolean),
    })
    .extend(withRootStore())
    .extend(withEnvironment())
    .views((self) => ({
      get availabilityString() {
        return `${self.redemptions} of ${self.maxRedemptions} Offer${self.maxRedemptions > 1 ? "s" : ""} Redeemed`
      },
      validDateString(dateFormat = "MMM d", separator = "-") {
        const startDatePST = utcToZonedTime(self.startDate, "America/Los_Angeles")
        const endDatePST = utcToZonedTime(self.endDate, "America/Los_Angeles")

        return `Valid ${format(startDatePST, dateFormat)} ${separator} ${format(endDatePST, dateFormat)}`
      },
      startDateString(dateFormat = "MMM d") {
        const startDatePST = utcToZonedTime(self.startDate, "America/Los_Angeles")
        return format(startDatePST, dateFormat)
      },
      endDateString(dateFormat = "MMM d") {
        const endDatePST = utcToZonedTime(self.endDate, "America/Los_Angeles")
        return format(endDatePST, dateFormat)
      },
      get expired() {
        return new Date() > self.endDate
      },
      get maxRedemptionsReached() {
        if (self.maxRedemptions == null) return false

        return self.redemptions >= self.maxRedemptions
      },
      get isPreview() {
        return self.status === EPromotionStatus.preview
      },
    }))
    .views((self) => ({
      get extendedTags() {
        return [...self.tags, self.expired && "Expired"].filter((notFalsy) => notFalsy)
      },
      get lineItems(): IPromotionLineItem[] {
        let lineItems = []
        self.sections.forEach((section) => {
          section.offerGroups.forEach((offerGroup) => {
            lineItems = lineItems.concat(offerGroup.lineItems)

            offerGroup.itemGroups.forEach((itemGroup) => {
              lineItems = lineItems.concat(itemGroup.lineItems)
            })
          })
        })
        return lineItems
      },
    }))
    .views((self) => ({
      anyPromotionWarnings(): boolean {
        return self.lineItems.some((li) => li.promotionQualifyError)
      },
    }))
    .actions((self) => ({
      applyCartWarnings(cart: ICart) {
        const cartItems = cart.cartItems
        const updateLineItemPromotionBooleansUsingCart = (lineItem) => {
          const productNumber = lineItem.product.number
          const relevantCartItems = cartItems.filter((ci) => ci.product.number === productNumber)
          if (!relevantCartItems.length) return

          if (relevantCartItems.some((ci) => ci.promotionQualify == false && ci.promotionApplied == false))
            lineItem.promotionQualifyError = true
        }
        self.lineItems.forEach(updateLineItemPromotionBooleansUsingCart)
        self.cartWarningsApplied = true
      },
    })),
  {
    preProcessor(snapshot: any) {
      return {
        ...snapshot,
        startDate: snapshot && new Date(snapshot.startDate),
        endDate: snapshot && new Date(snapshot.endDate),
      }
    },
  },
)

export interface IPromotion extends Instance<typeof PromotionModel> {}
