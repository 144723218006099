import { Instance, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../lib"
import { IProduct } from "../types"
import { priceStringToInteger, transformLegacyProduct } from "../utils"
import { formatAsPercent } from "../utils/formatter"

export const CartItemModel = types
  .model("CartItemModel")
  .props({
    id: types.maybeNull(types.number),
    product: types.maybeNull(types.frozen<IProduct>()),
    quantity: types.maybeNull(types.number),
    unitPrice: types.maybeNull(types.number),
    lineAmount: types.maybeNull(types.number),
    promotionQualify: types.maybeNull(types.boolean),
    promotionApplied: types.maybeNull(types.boolean),
  })
  .extend(withRootStore())
  .extend(withEnvironment())
  .views((self) => ({}))
  .views((self) => ({
    get isDiscounted(): boolean {
      const preDiscountLineAmount = self.quantity * self.product.unitPrice
      return preDiscountLineAmount != self.lineAmount || false
    },
    get formattedDiscount(): string {
      if (!self.lineAmount) return
      const discountDecimal = 1 - self.lineAmount / (self.product.unitPrice * self.quantity)
      return formatAsPercent(discountDecimal * 100)
    },
    get lineAmountAsNumber(): number {
      // This is a major code smell, but this is just how the backend was designed.
      return self.lineAmount
    },
    get regularPriceAsNumber(): number {
      return self.product.unitPrice
    },
  }))
  .preProcessSnapshot((snapshot) => {
    if (!snapshot) return snapshot

    return {
      ...snapshot,
      unitPrice:
        snapshot.unitPrice != null && typeof snapshot.unitPrice === "string"
          ? priceStringToInteger(snapshot.unitPrice)
          : snapshot.unitPrice,
      lineAmount:
        snapshot.lineAmount != null && typeof snapshot.lineAmount === "string"
          ? priceStringToInteger(snapshot.lineAmount)
          : snapshot.lineAmount,
      // Migrate product prices if a product is provided
      product: snapshot.product != null ? transformLegacyProduct(snapshot.product) : snapshot.product,
    }
  })

export interface ICartItem extends Instance<typeof CartItemModel> {}
