import * as R from "ramda"
export const formatAsPercent = (num) => {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num / 100)
}

// For V2 API
export const integerToPrice = (num) => {
  num ||= 0
  num /= 100
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num)
}

// Currency set to USD so that no currency indicator appears (does not change any values)
export const formatAsPrice = (num) => {
  num ||= 0
  num = parseFloat(num)
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num)
}

// formats a date object into MMM DD, YYYY
export const dateToStringFormat = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date)
}

// formats a date object into MM/DD/YYYY
export const invoiceDateFormat = (date) => {
  // guard clause to prevent formatting null
  var str = stringToDate(date) || new Date()
  return new Intl.DateTimeFormat("en-US").format(str)
}

// formats a string object back into Date MMM DD, YYYY
export const stringToDate = (dateString) => {
  return Date.parse(dateString)
}

// formats a date object into YYYY-MM-DD
export const numericDateToStringFormat = (date) => {
  var formatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
  const str = formatter.formatToParts(date)
  const dateString = `${str[4].value}-${str[0].value}-${str[2].value}`
  return dateString
}

// formats string to be all lower case besides the first character
export const capitalizeFirstLetter = (string) => {
  if (string != null) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  return string
}

export const priceStyleSplitter = (price) => {
  let priceStr = price.toString()
  if (!price.toString().includes(".")) priceStr += ".00"
  let priceArr = priceStr.split(".")
  if (priceArr[1].length < 2) {
    priceArr[1] += "0"
  }
  return priceArr
}

export const intPriceStyleSplitter = (price) => {
  // Convert the price to a string and pad with leading zeros for cents
  const priceString = price.toString()

  // Split into dollars and cents
  const dollars = priceString.slice(0, -2) || "0" // Extract everything except the last 2 characters (dollars)
  const cents = priceString.slice(-2).padStart(2, "0") // Extract the last 2 characters and ensure it has 2 digits

  return [dollars, cents]
}
